/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, Helvetica Neue, sans-serif; }

@import "node_modules/prismjs/themes/prism-coy.css";
@import "node_modules/prismjs/plugins/line-numbers/prism-line-numbers.css";

*:has(.sticky) {
  overflow: visible !important;
}
